import React from 'react';
import Link, { LinkAlignment, LinkType } from '@atoms/Link';
import Cards from '@organisms/Cards/Cards';

import RichText, { RichTextTypes } from '@utils/parsers/RichText';
import Paragraph from '@molecules/Paragraph';

const SegmentProxy = ({ component }: any) => {
  switch (component.sys?.contentType.sys.id) {
    case 'heading':
      return (
        <div
          key={component.id}
          className={`mb-16 text-oxford-blue-700 lg:mb-24`}
        >
          <RichText
            data={component.body}
            type={RichTextTypes.FULL}
            tag={`${component.internalName} | ${component.sys?.contentType.sys.id}`}
          />
        </div>
      );
    case 'paragraph':
      return <Paragraph data={component} />;
    case 'contentLink':
      return (
        <Link
          key={component.id}
          type={LinkType.ARROW}
          align={LinkAlignment.DEFAULT}
          href={component.link.link}
          className="mt-16 lg:mt-24"
          tag={`${component.internalName} | Arrow Link`}
        >
          {component.link.text}
        </Link>
      );
    case 'cards':
      return <Cards data={component} />;
    default:
      return null;
  }
};

export default SegmentProxy;

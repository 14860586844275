import React from 'react';
import Accordion from '@molecules/Accordion';
import Banner from '@molecules/Banner/Banner';
import Columns from '@molecules/Columns';
import Cards from '@organisms/Cards/Cards';
import ConstructedImages from '@molecules/ConstructedImages/ConstructedImages';
import Link from '@atoms/Link';
import List from '@molecules/List';
import Overline from '@atoms/Overline';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';
import Paragraph from '@molecules/Paragraph';
import Segment from '@organisms/Segment/Segment';
import Toplist from '@organisms/Toplist/Toplist';
import Table from '@molecules/Table';

const HomepageProxy = ({ component }: any) => {
  switch (component.sys?.contentType.sys.id) {
    case 'accordion':
      return <Accordion data={component} />;
    case 'contentLink':
      return <Link data={component} />;
    case 'banner':
      return <Banner data={component} />;
    case 'cards':
      return <Cards data={component} />;
    case 'columns':
      return <Columns data={component} />;
    case 'constructedImages':
      return <ConstructedImages data={component} />;
    case 'heading':
      return (
        <RichText
          data={component.body}
          type={RichTextTypes.FULL}
          tag={`internalName | ${component.sys?.contentType.sys.id}`}
        />
      );
    case 'list':
      return <List data={component} />;
    case 'overline':
      return <Overline data={component} />;
    case 'paragraph':
      return <Paragraph data={component} />;
    case 'segment':
      return <Segment data={component} />;
    case 'toplist':
      return <Toplist data={component} />;
    case 'table':
      return <Table data={component} />;
    default:
      return null;
  }
};

export default HomepageProxy;

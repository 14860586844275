import React from 'react';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';

export type OverLineTypes = {
  data: {
    internalName: string;
    alignment: string;
    body: {
      raw: string;
    };
    sys: any;
  };
};
const OverLine = ({
  data: { alignment, body, internalName, sys },
}: OverLineTypes) => {
  const parentTag = `${internalName} | ${sys.contentType.sys.id}`;

  let alignContainer =
    alignment == 'left' ? 'start' : alignment == 'right' ? 'end' : alignment;

  if (alignContainer === null) alignContainer = 'start';

  return (
    <div
      className={`flex justify-${alignContainer} text-${alignContainer} text-16 tracking-widest lg:text-20`}
    >
      <RichText data={body} type={RichTextTypes.SIMPLE} tag={parentTag} />
    </div>
  );
};

export default OverLine;

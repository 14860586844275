import React from 'react';
import MainTemplate from '@templates/MainTemplate/MainTemplate';
import Section from '@utils/grid/Section/Section';
import BackToTop, { BottomMargin } from '@atoms/BackToTop';
import HomepageProxy from '@utils/proxies/HomepageProxy/HomepageProxy';
import ConditionalWrapper from '@utils/functional/ConditionalWrapper/ConditionalWrapper';
import Hero from '@molecules/Hero/Hero';

const Homepage = ({ pageContext: { pageData } }: any) => {
  const { components, hero } = pageData;

  return (
    <MainTemplate data={pageData.mainTemplate} page={pageData.pageInfo}>
      <Hero data={hero} />
      <div className="col-span-4 flex w-full flex-col container-spacing pt-32 lg:pt-48">
        {components &&
          components.map((section: any) => {
            let sectionCss = '';
            let hasChild = false;
            let isRounded = false;

            const bgColor =
              section.backgroundColor === 'Grey' ? 'bg-denim-blue-100/10' : '';
            const alignment =
              section.alignment === 'Center' ? 'text-center' : 'text-left';
            const width =
              section.width === 'Small'
                ? 'container'
                : 'items-center';

            if (
              section.backgroundColor !== 'White' &&
              section.width === 'Small'
            ) {
              sectionCss = 'section-spacing flex flex-col section-with-background px-24 lg:px-64';
              hasChild = true;
              isRounded = true;
            } else if (
              section.backgroundColor !== 'White' &&
              section.width !== 'Small'
            ) {
              sectionCss =
                'container section-spacing section-with-background flex flex-col';
              hasChild = true;
            }

            return (
              <Section
                key={section.id}
                data={section}
                className={`mx-auto flex w-full flex-col section-spacing ${
                  isRounded ? 'rounded-lg' : ''
                } ${width} ${bgColor} ${alignment}`}
              >
                <ConditionalWrapper
                  condition={hasChild}
                  wrapper={(children: any) => (
                    <div className={sectionCss ? sectionCss : undefined}>
                      {children}
                    </div>
                  )}
                >
                  {section.components &&
                    section.components.map((component: any) => {
                      if (component.id !== undefined) {
                        return (
                          <HomepageProxy
                            key={component.id}
                            component={component}
                          />
                        );
                      }
                    })}
                </ConditionalWrapper>
              </Section>
            );
          })}
      </div>
      <BackToTop size={BottomMargin.DEFAULT} />
    </MainTemplate>
  );
};

export default Homepage;

import React from 'react';
import SegmentProxy from '@utils/proxies/SegmentProxy/SegmentProxy';

export type SegmentProps = {
  data: {
    internalName: string;
    toggleSize: boolean;
    firstSection: any;
    secondSection: any;
  };
};

const Segment = ({
  data: { toggleSize, firstSection, secondSection },
}: SegmentProps) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div
        className={`w-full ${
          toggleSize ? 'lg:w-[60%]' : 'lg:w-[40%]'
        } flex flex-col section-spacing`}
      >
        {firstSection.map((component: any) => (
          <SegmentProxy key={component.id} component={component} />
        ))}
      </div>
      <div
        className={`w-full ${
          toggleSize ? 'lg:w-[40%]' : 'lg:w-[60%]'
        } lg:pl-80`}
      >
        {secondSection.map((component: any) => (
          <SegmentProxy key={component.id} component={component} />
        ))}
      </div>
    </div>
  );
};

export default Segment;
